
import { defineComponent, ref } from "vue";
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "license",
  components: {},
  props: {
    license: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      licenseContent: [],
      copyrightContent: [],
    };
  },
  methods: {
    queryPackageLicenseAndCopyright() {
      const packageId: string = this.$route.params.id.toString();
      SbomDataService.queryPackageLicenseAndCopyright(packageId)
        .then((response: ResponseData) => {
          const { licenseContent, copyrightContent } = response.data;
          this.licenseContent = licenseContent;
          this.copyrightContent = copyrightContent;
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: "error",
            });
          }
          this.licenseContent = [];
          this.copyrightContent = [];
        });
    },
    goPath(item) {
      if (item.licenseUrl) {
        window.open(item.licenseUrl, "_blank");
      }
    },
  },
  mounted() {
    this.queryPackageLicenseAndCopyright();
  },
});
