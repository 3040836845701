import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133d7213"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "txt" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenseContent, (license, licenseIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "buttons",
        key: licenseIndex
      }, [
        (license.licenseName)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              effect: "dark",
              content: license.licenseName,
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["licenseItem", { canClick: license.licenseUrl }]),
                  onClick: ($event: any) => (_ctx.goPath(license))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(['dot', license.legal ? 'green' : 'red'])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(license.licenseId), 1)
                ], 10, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["content"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["licenseItem", { canClick: license.licenseUrl }]),
              onClick: ($event: any) => (_ctx.goPath(license))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(['dot', license.legal ? 'green' : 'red'])
              }, null, 2),
              _createElementVNode("span", _hoisted_5, _toDisplayString(license.licenseId), 1)
            ], 10, _hoisted_4))
      ]))
    }), 128))
  ]))
}