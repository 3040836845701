
import { defineComponent, ref } from "vue";
import { IsOpenEulerByProductName } from "@/utils";
import licenseTmp from "./licenseTmp.vue";

export default defineComponent({
  name: "sbom-data",
  components: {
    licenseTmp,
  },
  props: {
    sbomData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vulnerabilityData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      vluProps: [
        { label: "致命", prop: "criticalVulCount" },
        { label: "高", prop: "highVulCount" },
        { label: "中", prop: "mediumVulCount" },
        { label: "低", prop: "lowVulCount" },
        { label: "无风险", prop: "noneVulCount" },
        { label: "未知", prop: "unknownVulCount" },
      ],
      flag: false,
    };
  },
  methods: {
    goPath(url) {
      if (url) {
        const reg =
          /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;
        const res: any = reg.exec(url);
        if (res[0]) {
          window.open(res[0], "_blank");
        }
      }
    },
    formatPointer(text) {
      return text && (text.includes("https://") || text.includes("http://"));
    },
  },
  mounted() {
    this.flag = this.$route.params.flag.toString() === "true" ? true : false;
  },
});
