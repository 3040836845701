
import { defineComponent, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import ResponseData from "@/types/ResponseData";
import SbomDataService from "@/services/SbomDataService";
import { ElMessage } from "element-plus";
function defaultPagination() {
  return {
    pageNum: 1,
    pageSize: 10,
    total: 0,
  };
}
export default defineComponent({
  name: "package",
  components: {},
  data() {
    return {
      Search: Search,
      config: [
        { title: "Package Info", prop: "packageInfo" },
        { title: "Module Info", prop: "module" },
        { title: "Dependency", prop: "dependency" },
        { title: "Runtime Dependency", prop: "runtime" },
      ],
      columns: {
        packageInfo: [
          { label: "软件包名", prop: "pkgName" },
          { label: "包管理类型", prop: "type" },
          { label: "name", prop: "name" },
          { label: "version", prop: "version" },
          { label: "qualifiers", prop: "qualifiers" },
        ],
        module: [
          { label: "软件包名", prop: "pkgName" },
          { label: "包管理类型", prop: "type" },
          { label: "namespace", prop: "namespace" },
          { label: "name", prop: "name" },
          { label: "version", prop: "version" },
        ],
        dependency: [
          { label: "软件包名", prop: "pkgName" },
          { label: "包管理类型", prop: "type" },
          { label: "namespace", prop: "namespace" },
          { label: "name", prop: "name" },
          { label: "version", prop: "version" },
        ],
        runtime: [
          { label: "软件包名", prop: "pkgName" },
          { label: "包管理类型", prop: "type" },
          { label: "name", prop: "name" },
          { label: "version", prop: "version" },
          { label: "qualifiers", prop: "qualifiers" },
        ],
      },
      search: {
        packageInfo: "",
        module: "",
        dependency: "",
        runtime: "",
      },
      pagination: {
        packageInfo: defaultPagination(),
        module: defaultPagination(),
        dependency: defaultPagination(),
        runtime: defaultPagination(),
      },
      tableData: {
        packageInfo: [] as Map<string, any>[],
        module: [] as Map<string, any>[],
        dependency: [] as Map<string, any>[],
        runtime: [] as Map<string, any>[],
      },
      dataList: {
        packageList: [] as Map<string, any>[],
        provideList: [] as Map<string, any>[],
        externalList: [] as Map<string, any>[],
        relationshipList: [] as Map<string, any>[],
      },
    };
  },
  methods: {
    queryPackageBinary(packageId: string) {
      SbomDataService.queryPackageBinaryManagement(packageId, "ALL")
        .then((response: ResponseData) => {
          this.dataList = response.data;
          this.getData();
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: "error",
            });
          }
          this.dataList = {
            packageList: [] as Map<string, any>[],
            provideList: [] as Map<string, any>[],
            externalList: [] as Map<string, any>[],
            relationshipList: [] as Map<string, any>[],
          };
        });
    },
    getData() {
      const { packageInfo, module, dependency, runtime } = this.search;
      const { packageList, provideList, externalList, relationshipList } =
        this.dataList;
      this.tableData.packageInfo = filterTableData(packageInfo, packageList);
      this.tableData.module = filterTableData(module, provideList);
      this.tableData.dependency = filterTableData(dependency, externalList);
      this.tableData.runtime = filterTableData(runtime, relationshipList);
    },
    handlePageChange(prop: string, val: number) {
      console.log(prop, val);
    },
    handleSizeChange(prop: string, val: number) {
      console.log(prop, val);
    },
  },
  mounted() {
    this.queryPackageBinary(this.$route.params.id.toString());
  },
});

const filterTableData: any = (
  searchExternal: string,
  tableDataList: Map<string, any>[]
) => {
  let tableData = transferPurl(tableDataList);
  if (tableData.length == 0) {
    return tableData;
  }
  return tableData.filter(
    (data: any) =>
      !searchExternal ||
      String(data.name).toLowerCase().includes(searchExternal.toLowerCase()) ||
      String(data.namespace)
        .toLowerCase()
        .includes(searchExternal.toLowerCase())
  );
};

const transferPurl: any = (list: any) => {
  let purlList: any[] = [];
  list.forEach((element: any) => {
    if (element.type === "purl") {
      purlList.push({
        ...element.purl,
        pkgName: element.pkgName,
      });
    }
  });
  return purlList;
};
