
import { defineComponent, ref } from "vue";
import { Search } from '@element-plus/icons-vue'
import ResponseData from "@/types/ResponseData";
import SbomDataService from "@/services/SbomDataService";
import { ElMessage } from 'element-plus';
function defaultPagination() {
  return {
    pageNum: 1,
    pageSize: 10,
    total: 0
  }
}
export default defineComponent({
  name: "upstreamAndPatchInfo",
  components: {
    
  },
  data() {
    return {
      Search: Search,
      config: [
        { title: '上游社区', prop: 'upstream' },
        { title: 'Patch', prop: 'patch' },
      ],
      columns: {
        upstream: [
          { label: 'URL', prop: 'url', link: true },
        ],
        patch: [
          { label: 'URL', prop: 'url', link: true },
        ],
      },
      search: {
        upstream: '',
        patch: '',
      },
      pagination: {
        upstream: defaultPagination(),
        patch: defaultPagination(),
      },
      tableData: {
        upstream: [] as Map<string, any>[],
        patch: [] as Map<string, any>[]
      },
      dataList: {
        upstreamList: [] as Map<string, any>[],
        patchList: [] as Map<string, any>[]
      }
    };
  },
  methods: {
    queryUpstreamAndPatchInfo(packageId: string) {
      SbomDataService.queryUpstreamAndPatchInfo(packageId)
        .then((response: ResponseData) => {
          this.dataList = response.data
          this.getData()
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: 'error'
            })
          }
          this.dataList = {
            upstreamList: [] as Map<string, any>[],
            patchList: [] as Map<string, any>[]
          }
        });
    },
    getData() {
      const { upstream, patch } = this.search
      const { upstreamList, patchList } = this.dataList
      this.tableData.upstream = filterTableData(upstream, upstreamList);
      this.tableData.patch =  filterTableData(patch, patchList);
    },
    handlePageChange(prop: string, val: number) {
      console.log(prop,val)
    },
    handleSizeChange(prop: string, val: number) {
      console.log(prop,val)
    },
    goPath(item) {
      if(item.url) {
        window.open(item.url, '_blank')
      }
    }
  },
  mounted() {
    this.queryUpstreamAndPatchInfo(this.$route.params.id.toString());
  },
});

const filterTableData: any = (searchExternal: string, tableDataList: Map<string, any>[]) => {
  return tableDataList.filter((data: any) => !searchExternal ||
    String(data.url).toLowerCase().includes(searchExternal.toLowerCase())
  )
}
