
import { defineComponent, ref } from "vue";
import sbomData from "./components/sbomData.vue";
import SbomVulnerabilityTable from "@/components/SbomVulnerabilityTable.vue";
import packageDependency from "./components/packageDependency.vue";
import upstreamAndPatchInfo from "./components/upstreamAndPatchInfo.vue";
import SbomPackage from "@/types/SbomPackage";
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import { ParseUriProductName } from "@/utils";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "sbom-package-detail",
  components: {
    sbomData,
    SbomVulnerabilityTable,
    packageDependency,
    upstreamAndPatchInfo,
  },
  data() {
    return {
      activeName: "sbomData",
      packageDetails: {} as SbomPackage,
      productName: "",
      vulnerabilityData: {},
      ParseUriProductName: ParseUriProductName,
    };
  },
  methods: {
    queryPackageDetails(packageId: string) {
      SbomDataService.querySbomPackageById(packageId)
        .then((response: ResponseData) => {
          this.packageDetails = response.data;
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: "error",
            });
          }
          this.packageDetails = {} as SbomPackage;
        });
    },
    queryPackageVuls(packageId: string) {
      SbomDataService.queryPackageVulsById(packageId)
        .then((response: ResponseData) => {
          this.vulnerabilityData = response.data;
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: "error",
            });
          }
          this.vulnerabilityData = {};
        });
    },
  },
  mounted() {
    this.productName = this.ParseUriProductName();
    this.queryPackageDetails(this.$route.params.id.toString());
    this.queryPackageVuls(this.$route.params.id.toString());
  },
});
